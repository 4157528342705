import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Field,
  Input,
  Select,
  useId,
  Label,
  Textarea,
  Checkbox,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Switch,
  Tooltip,
} from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";
import { DatePicker, DayOfWeek } from "@fluentui/react-datepicker-compat";
import {
  checkUserPermission,
  formatedDate,
  formatedDatepickerDate,
  getClientType,
  getFileNameFromUrl,
  getYes_NoConversion,
} from "../../utils";
import TimePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import UploadIcon from "../../assets/uploadicon.svg";
import close from "../../assets/close.svg";
import CreatableSelect from "react-select/creatable";
import ReactQuill from "react-quill";
import ShowMoreText from "react-show-more-text";
import "react-quill/dist/quill.snow.css";
import { ClipboardTextEdit20Regular } from "@fluentui/react-icons";
import TextEditor from "../TextEditor";
import * as DOMPurify from "dompurify";
import ReactQuillComponent from "../ReactQuillComponent";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import Provider from "../../pages/client/Provider/Provider";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { ChevronDown24Filled, DismissFilled } from "@fluentui/react-icons";
import apiServices from "../../service";
import { UserPermissionsList } from "../../config";
import { InfoIcon } from "@fluentui/react-icons-mdl2";

type RenderFormElementsProps = {
  style?: React.CSSProperties;
  [key: string]: any;
};
interface Option {
  readonly label: string;
  readonly value: string;
}
const useStyles = makeStyles({
  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    fontSize: "12px",
  },
  subTitle: {
    fontSize: "14px",
  },
});

const createOption = (label: string) => ({
  label,
  value: label,
});
const defaultOptions = [createOption("")];

const RenderFormElements = (props: RenderFormElementsProps) => {
  const selectedruleItem = useSelector(
    (state: any) => state.ruleid?.selectedRule
  );
  let { formik } = props;
  const selectId = useId();
  const textEditorId = useId("text-editor");
  const classes = useStyles();
  const [datevalue, setDatevalue] = useState();
  const [checked, setChecked] = React.useState(false);
  const [checkedData, setCheckedData] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [initialOption, setInitialOption] = useState<Option | null>();
  const [value, setValue] = useState<Option | null>();
  const [openTextEditor, setOpenTextEditor] = useState(false);
  const [valueLoader, setValueLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [toggle, setToggle] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [getProviderId, setGetProviderId] = useState<{ [key: string]: any }>(
    {}
  );
  const [getRuleId, setGetRuleId] = useState<{ [key: string]: any }>({});
  const [documentInfo, setDocumentInfo] = useState<{ [key: string]: any }>({});
  const [docFiles, setDocFiles] = useState<Array<{ [key: string]: any }>>([]);
  //
  const [selectedOption, setSelectedOption] = useState("");
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const handleSelectChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const onFormatDate = (date?: Date): string => {
    return !date ? "" : moment(date).format("DD-MM-YYYY");
  };

  function providerId(id: { [key: string]: any }) {
    setGetProviderId(id);

    if (props?.item?.field) {
      formik.setFieldValue(props?.item?.field, id.id);
    }
  }
  function ruleId(id: { [key: string]: any }) {
    setGetRuleId(id);

    if (props?.item?.field) {
      formik.setFieldValue(props?.item?.field, id.id);
    }
  }
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (
        inputRef.current &&
        inputRef.current.contains(document.activeElement)
      ) {
        inputRef.current.blur();
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);
  // const onChange = React.useCallback(
  //   (ev: any) => {
  //     setToggle(ev.currentTarget.toggle);
  //   },
  //   [setToggle]
  // );
  useEffect(() => {
    setValue(null);
    setOptions(props.options ? props.options : defaultOptions);

    if (
      props.options &&
      !valueLoader &&
      props.item &&
      props.item.listType &&
      ["ModifiableList", "MasterDatawithNewEntry"].includes(props.item.listType)
    ) {
      setOptions(props.options);
      const valueIs = formik.values[props.item.field];
      let isValuePresent = false;

      if (
        props.options !== undefined &&
        props.option !== null &&
        props.options.length > 0 &&
        valueIs !== undefined &&
        valueIs !== null
      ) {
        isValuePresent = props.options.some(
          (obj: any) => obj.value.toLowerCase() === valueIs.toLowerCase()
        );
      }

      if (valueIs !== "" && valueIs !== null) {
        const newOption = createOption(valueIs);
        setValue(newOption);

        if (!isValuePresent) {
          setOptions((prev) => [...prev, newOption]);
        }
      } else {
        setValue(null);
      }
    }

    return () => {
      setValue(null);
    };
  }, [props.options, valueLoader]);

  useEffect(() => {
    if (formik.values[props.item.field] !== undefined) {
      setValueLoader(false);
    }
  }, [formik.values[props.item.field]]);

  useEffect(() => {
    if (
      props.caseDetails !== undefined &&
      Object.keys(getProviderId).length === 0
    ) {
      setGetProviderId(props.caseDetails);
    }
  }, [props.caseDetails, getProviderId]);
  useEffect(() => {
    if (
      formik.values.unit_price !== undefined &&
      formik.values.fund_units !== undefined
    ) {
      formik.setFieldValue(
        "fund_value",
        (formik.values.unit_price * formik.values.fund_units).toFixed(4)
      );
    }
  }, [formik.values.unit_price, formik.values.fund_units]);

  useEffect(() => {
    if (props.context === "ASSET_INVESTMENT_COMMISSION_DISTRIBUTION") {
      if (
        formik.values.amount !== null &&
        formik.values.party_type !== undefined &&
        formik.values.party_name !== undefined &&
        formik.values.commission_rule_id !== undefined
      ) {
        formik.setFieldValue("amount", selectedruleItem?.amount);
        formik.setFieldValue("party_type", selectedruleItem?.party_type);
        formik.setFieldValue("party_name", selectedruleItem?.party_name);
        formik.setFieldValue("commission_rule_id", selectedruleItem?.id);
        setValue({
          value: selectedruleItem?.party_name,
          label: selectedruleItem?.party_name,
        });
      }
    }
  }, [selectedruleItem]);

  // console.log(formik.setFieldValue(formik.values.party_name), "--fomik values");

  // useEffect(() => {
  //   if (
  //     props.context &&
  //     props.item &&
  //     ["FACT_FIND_NOTES", "CONTACTS_NOTES"].includes(props.context)
  //   ) {
  //     if (
  //       props.item.type === "fileUpload" &&
  //       props.item.field === "file_link" &&
  //       props.item.value != ""
  //     ) {
  //       setDocFiles([]);
  //       getDocDetail(formik.values[props.item.field]);
  //     } else if (
  //       formik.values.attachment_file != undefined &&
  //       formik.values.attachment_file !== null &&
  //       formik.values.attachment_file != ""
  //     ) {
  //       const fileIds = formik.values.attachment_file.split(",");
  //       setDocFiles([]);
  //       for (const fileId of fileIds) {
  //         getDocDetail(fileId);
  //       }
  //     }
  //     console.log(" props.item", props.item);
  //     console.log(" formik.values", formik.values);
  //   }
  // }, [props.context, props.item, formik.values[props.item.field]]);
  const handleCreate = (inputValue: string, name: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      // setOptions((prev) => [...prev, newOption]);
      setValue(newOption);
      formik.setFieldValue(name, inputValue);
      if (props.newSelectOptionAdded !== undefined) {
        props.newSelectOptionAdded(name, newOption, "add");
      }
      /* We can add function to add this new value to our option json */
    }, 1000);
  };

  // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  // const removeFile = (index: number) => {
  //   setUploadedFiles([]);
  // };

  const validateMaxMinDate = (date: any) => {
    if (date && date !== undefined) {
      if (date === "today") {
        return new Date();
      } else {
        return new Date(date);
      }
    } else {
      return undefined;
    }
  };
  // const getDocDetail = (docId: string) => {
  //   if (docId) {
  //     apiServices.document
  //       .getDocument(docId, props.customer_id)
  //       .then((res) => {
  //         if (res.data && res.data.data) {
  //           setDocumentInfo(res.data.data);
  //           setDocFiles((prevDocFiles) => [...prevDocFiles, res.data.data]);
  //         }
  //       })
  //       .catch((err: any) => console.log(err));
  //   }
  // };
  // const onFormatDate = (date?: Date): string => {
  //   // return moment(date).format("DD/MM/YYYY");

  //   // return !date
  //   //   ? ""
  //   //   : `${date?.getDate()}/${
  //   //       date?.getMonth() ? date.getMonth() + 1 : date?.getMonth()
  //   //     }/${date?.getFullYear()}`;

  //   return !date ? "" : moment(date).format("DD/MM/YYYY");
  // };

  const getElement = (element: any) => {
    const fieldInfo = {
      name: element.field,
      label: element.label,
      options: element.options,
      placeholder: element.placeholder ? element.placeholder : "",
      readOnly: element.readOnly ? element.readOnly : undefined,
      required: element.required ? element.required : undefined,
      info: element.info ? element.info : undefined,
      min: element.min ? element.min : undefined,
      max: element.max ? element.max : undefined,
    };
    const review = [
      "review_assigned",
      "business_assigned",
      "appointment_assigned",
    ].includes(fieldInfo.name);

    const handleClearDate = () => {
      formik.setFieldValue(fieldInfo.name, null);
      if (props.customHandleChange !== undefined) {
        props.customHandleChange({
          target: { name: fieldInfo.name, value: null },
        });
      }
    };
    switch (element.type) {
      case "text":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : element.info !== undefined && element.info ? (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {element.label}{" "}
                    <span>
                      <Tooltip
                        content="Click Choose Distribution Button For Choose Rule"
                        relationship="label"
                      >
                        <Button
                          icon={<InfoIcon />}
                          size="small"
                          style={{ border: "none" }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                ) : (
                  <span>{element.label}</span>
                )}

                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined &&
              formik.errors[fieldInfo.name] !== null
                ? formik.errors[fieldInfo.name]
                : ""
            }`}

            // validationState="success"
          >
            <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            />
          </Field>
        );
        break;
      case "password":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              type="password"
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            />
          </Field>
        );
        break;
      case "selection-with-call-to-action":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            {/* <Button
              
              icon={<ChevronDown24Filled />}
            >
              Provider
            </Button> */}
            <div
              className="provider_button"
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    provider: true,
                    providerId: providerId,
                    getProviderId: getProviderId,
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Cancel",
                    },
                    no: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Select",
                      type: "primary",
                    },
                  })
                );
              }}
              // ALLOW_REASSIGN_ACTIONS
            >
              <Input
                style={{ display: "none" }}
                appearance="filled-lighter"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={fieldInfo.name}
                placeholder={fieldInfo.placeholder}
                disabled={fieldInfo.readOnly}
                value={getProviderId.id}
              />

              {getProviderId ? (
                <span>
                  {getProviderId.group_name}{" "}
                  {getProviderId.provider_name &&
                  getProviderId.provider_name !== "undefined"
                    ? getProviderId.provider_name
                    : ""}
                </span>
              ) : (
                <span>Provider</span>
              )}

              <ChevronDown24Filled />
            </div>
          </Field>
        );
        break;
      case "select-call-to-action":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            {/* <Button
              
              icon={<ChevronDown24Filled />}
            >
              Provider
            </Button> */}
            <div
              className="provider_button"
              onClick={() => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    rule: true,
                    ruleId: ruleId,
                    getRuleId: getRuleId,
                    cancel: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Cancel",
                    },
                    no: {
                      onclick: () => {
                        // setSeletedRule(selectedruleItem);
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Select",
                      type: "primary",
                    },
                  })
                );
              }}
              // ALLOW_REASSIGN_ACTIONS
            >
              <span></span>
              <ChevronDown24Filled />
            </div>
          </Field>
        );
        break;
      case "number":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              type="number"
              ref={inputRef}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
              min={fieldInfo.min !== undefined ? fieldInfo.min : undefined}
              max={fieldInfo.max !== undefined ? fieldInfo.max : undefined}
            />
            {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )}
          </Field>
        );
        break;
      case "email":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            />
            {/* {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )} */}
          </Field>
        );
        break;
      case "select":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            <Select
              id={selectId}
              appearance="filled-lighter"
              onChange={(e: any) => {
                const selectedIndex = e.target.selectedIndex;
                const selectedValue = e.target.value;
                const selectedLabel = e.target.options[selectedIndex].label;
                if (fieldInfo.name === "consultant_id") {
                  formik.setFieldValue(fieldInfo.name, selectedValue);
                  if (props.updateConsultantName !== undefined) {
                    props.updateConsultantName(selectedLabel);
                  }
                } else if (fieldInfo.name === "commission_type") {
                  formik.setFieldValue(fieldInfo.name, selectedLabel);
                  if (props.updateCommissionType !== undefined) {
                    props.updateCommissionType(selectedLabel);
                  }
                } else {
                  formik.setFieldValue(fieldInfo.name, selectedValue);
                }
              }}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              disabled={fieldInfo.readOnly}
            >
              {options &&
                options.length > 0 &&
                options.map(
                  (optn: { label: string; value: string }, i: any) => (
                    <option
                      key={i}
                      value={optn.value}
                      label={optn.label || ""}
                      selected={
                        ["commission_type"].includes(fieldInfo.name)
                          ? formik.values[fieldInfo.name] === optn.label
                          : ["consultant_id"].includes(fieldInfo.name)
                          ? formik.values[fieldInfo.name] === optn.value
                          : [
                              "applicable",
                              "pending",
                              "link_to_mortgage",
                              "category"
                            ].includes(fieldInfo.name)
                          ? (formik.values[fieldInfo.name]?.toString() ||
                              "") === optn.value
                          : ["joint_indicator", "owner"].includes(
                              fieldInfo.name
                            )
                          ? (formik.values[fieldInfo.name]?.toString() ||
                              "") === optn.value
                          : formik.values[fieldInfo.name] === optn.value
                      }
                    />
                  )
                )}
            </Select>

            {/* {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )} */}
          </Field>
        );
        break;
      case "datepicker":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={
              formik.errors[fieldInfo.name] &&
              formik.errors[fieldInfo.name] !== null
                ? "error"
                : "none"
            }
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined &&
              formik.errors[fieldInfo.name] !== null
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            {/* <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="date"
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            /> */}

            {/* <div className={`${formik?.values[fieldInfo.name]}` ? "date_picker" : "display_block"}> */}
            <div className={"date_picker"}>
              <DatePicker
                as="input"
                allowTextInput
                className=""
                placeholder=""
                formatDate={onFormatDate}
                appearance="filled-lighter"
                onSelectDate={(date) => {
                  formik.setFieldValue(fieldInfo.name, date);
                  if (props.customHandleChange !== undefined) {
                    props.customHandleChange({
                      target: { name: fieldInfo.name, value: date },
                    });
                  }
                }}
                onBlur={formik.handleBlur}
                maxDate={
                  element.maxDate !== undefined
                    ? validateMaxMinDate(element.maxDate)
                    : undefined
                }
                minDate={
                  element.minDate !== undefined
                    ? validateMaxMinDate(element.minDate)
                    : undefined
                }
                name={fieldInfo.name}
                value={
                  formik?.values[fieldInfo.name]
                    ? props.mode === "add"
                      ? formik?.values[fieldInfo.name]
                      : props.mode === "edit"
                      ? new Date(formik?.values[fieldInfo.name])
                      : null
                    : null
                }
                firstDayOfWeek={DayOfWeek.Monday}
              />
              {formik.values[fieldInfo.name] ? (
                <DismissFilled onClick={handleClearDate} />
              ) : null}
            </div>
            {/* {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )} */}
          </Field>
        );
        break;
      case "text-area":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        disabled={
                          formik?.values[fieldInfo.name] !== undefined &&
                          formik?.values[fieldInfo.name] !== null &&
                          formik?.values[fieldInfo.name] !== ""
                            ? false
                            : true
                        }
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            <Textarea
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            />
            {/* {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )} */}
          </Field>
        );
        break;
      case "check-box":
        return (
          <div>
            <Field
              orientation={props.isAdminForm ? "horizontal" : "vertical"}
              validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
              validationMessage={`${
                formik.errors[fieldInfo.name] !== undefined
                  ? formik.errors[fieldInfo.name]
                  : ""
              }`}
              // validationState="success"
            >
              <Checkbox
                checked={checked}
                onChange={() => setChecked((checked) => !checked)}
                label={
                  <>
                    {element.required !== undefined && element.required ? (
                      <span>
                        {element.label} <span className="required-item">*</span>
                      </span>
                    ) : (
                      <span> {element.label}</span>
                    )}
                  </>
                }
              />
            </Field>
          </div>
        );
        break;
      case "select-with-selectionList":
        const handleSelectWithParam = (inputValue: string) => {
          handleCreate(inputValue, fieldInfo.name);
        };
        return (
          <div>
            <Field
              orientation={props.isAdminForm ? "horizontal" : "vertical"}
              validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
              validationMessage={`${
                formik.errors[fieldInfo.name] !== undefined
                  ? formik.errors[fieldInfo.name]
                  : ""
              }`}
              label={
                <>
                  {element.required !== undefined && element.required ? (
                    <span>
                      {element.label} <span className="required-item">*</span>
                    </span>
                  ) : (
                    <span> {element.label}</span>
                  )}
                </>
              }
              // validationState="success"
            >
              <div
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                {element.prefix !== undefined && (
                  <Select
                    id={selectId}
                    appearance="filled-lighter"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={element.prefix.field}
                    style={{ width: "30%" }}
                  >
                    {props.prefixOptions !== undefined &&
                    props.prefixOptions.length > 0
                      ? props.prefixOptions.map(
                          (optn: { label: string; value: string }, i: any) => (
                            <option
                              key={i}
                              value={optn.value}
                              label={optn.label || optn.value}
                              selected={
                                formik.values[element.prefix.field] ==
                                optn.value
                                  ? true
                                  : false
                              }
                            />
                          )
                        )
                      : element.prefix.options.map(
                          (optn: { label: string; value: string }, i: any) => (
                            <option
                              key={i}
                              value={optn.value}
                              label={optn.label || optn.value}
                              selected={
                                formik.values[element.prefix.field] ==
                                optn.value
                                  ? true
                                  : false
                              }
                            />
                          )
                        )}
                  </Select>
                )}

                {/* <Select
                  id={selectId}
                  appearance="filled-lighter"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name={fieldInfo.name}
                  disabled={fieldInfo.readOnly}
                  style={{ width: "70%" }}
                >
                  <option value=""></option>
                  {element.options.map(
                    (optn: { label: string; value: string }, i: any) => (
                      <option
                        key={i}
                        value={optn.value}
                        label={optn.label || optn.value}
                        selected={
                          formik.values[fieldInfo.name] == optn.value
                            ? true
                            : false
                        }
                      />
                    )
                  )}
                </Select> */}
                <CreatableSelect
                  styles={{
                    control: (baseStyles: any, state) => ({
                      ...baseStyles,
                      border: "none",
                      borderColor: state.isFocused ? "grey" : "grey",
                    }),
                  }}
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  name={fieldInfo.name}
                  onChange={(newValue) => {
                    if (!newValue) {
                      formik.setFieldValue(fieldInfo.name, "");
                      setValue(null);
                      if (props.newSelectOptionAdded !== undefined) {
                        props.newSelectOptionAdded(
                          fieldInfo.name,
                          "",
                          "remove"
                        );
                      }
                    }
                    newValue &&
                      formik.setFieldValue(fieldInfo.name, newValue.value);
                    setValue(newValue);
                    if (props.customHandleChange !== undefined) {
                      props.customHandleChange({
                        target: {
                          name: fieldInfo.name,
                          value: newValue?.value,
                        },
                      });
                    }
                  }}
                  onCreateOption={handleSelectWithParam}
                  options={options}
                  value={value}
                  formatCreateLabel={(inputValue: string) => `${inputValue}`}
                />
              </div>
            </Field>
          </div>
        );
        break;
      case "selectionList":
        const handleCreateWithParam = (inputValue: string) => {
          handleCreate(inputValue, fieldInfo.name);
        };

        // const foundObject: Option | undefined = value.find(obj => obj.value === formik.values[fieldInfo.name]);
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            <CreatableSelect
              styles={{
                control: (baseStyles: any, state) => ({
                  ...baseStyles,
                  border: "none",
                  borderColor: state.isFocused ? "grey" : "grey",
                }),
              }}
              isClearable
              isDisabled={
                review &&
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_REASSIGN_ACTIONS
                ) === false
              }
              isLoading={isLoading}
              name={fieldInfo.name}
              onChange={(newValue) => {
                if (!newValue) {
                  formik.setFieldValue(fieldInfo.name, "");
                  setValue(null);
                  if (props.newSelectOptionAdded !== undefined) {
                    props.newSelectOptionAdded(fieldInfo.name, "", "remove");
                  }
                }
                newValue &&
                  formik.setFieldValue(fieldInfo.name, newValue.value);
                setValue(newValue);
                if (props.customHandleChange !== undefined) {
                  props.customHandleChange({
                    target: { name: fieldInfo.name, value: newValue?.value },
                  });
                }
              }}
              onCreateOption={handleCreateWithParam}
              options={options}
              value={value}
              formatCreateLabel={(inputValue: string) => `${inputValue}`}
            />
          </Field>
        );
        break;
      case "timePicker":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            <TimePicker
              selected={startDate}
              onChange={(date: any) => {
                if (date) {
                  setStartDate(date);

                  let formtDate = new Date(date);
                  formtDate.setMinutes(
                    formtDate.getMinutes() - formtDate.getTimezoneOffset()
                  );

                  formik.setFieldValue(fieldInfo.name, formtDate.toISOString());
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
            />
          </Field>
        );
        break;
      case "fileUpload":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            <Dropzone
              onDrop={(acceptedFiles: File[]) => {
                if (acceptedFiles.length > 0) {
                  props?.setUploadedFiles([acceptedFiles[0]]);
                  formik.setFieldValue(fieldInfo.name, acceptedFiles[0]);
                }
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="fileupload-container">
                    <input {...getInputProps()} />
                    <div className="file-upload">
                      <img src={UploadIcon} width={40} />
                    </div>
                    <p style={{ textAlign: "center" }}>
                      Click or Drag the file here to upload
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>

            <div>
              <ul>
                {props?.uploadedFiles.map((file: any, index: number) => (
                  <div className="list-upload-item" key={index}>
                    <li>
                      {/* Render each file's name and size */}
                      {file.name} - {(file.size / 1024).toFixed(2)} KB
                      <img
                        className="file-upload-close"
                        src={close}
                        onClick={() => props?.removeFile(index)}
                        width={20}
                      />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </Field>
        );
        break;
      case "text-editor":
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
          >
            <div style={{ backgroundColor: "#ffff", maxWidth: 350 }}>
              <TextEditor
                inputData={
                  formik?.values[fieldInfo.name] !== undefined &&
                  formik?.values[fieldInfo.name] !== ""
                    ? formik.values[fieldInfo.name]
                    : ""
                }
                onChange={(text: string) => {
                  formik.setFieldValue(fieldInfo.name, text);
                  if (props.customHandleChange !== undefined) {
                    props.customHandleChange({
                      target: { name: fieldInfo.name, value: text },
                    });
                  }
                }}
                name={fieldInfo.name}
              />
            </div>
          </Field>
        );
        break;

      case "toggle-button":
        return (
          <Switch
            checked={
              formik?.values[fieldInfo.name] !== undefined
                ? formik?.values[fieldInfo.name] == 0
                  ? false
                  : true
                : toggle
            }
            name={fieldInfo.name}
            label={element.label}
            labelPosition="before"
            onChange={(ev) => {
              setToggle(!toggle);
              formik.setFieldValue(fieldInfo.name, ev.target.checked ? 1 : 0);
            }}
          />
        );
        break;

      default:
        return (
          <Field
            label={
              <>
                {element.required !== undefined && element.required ? (
                  <span>
                    {element.label} <span className="required-item">*</span>
                  </span>
                ) : (
                  <span> {element.label}</span>
                )}
                {props.primaryContactFields !== undefined &&
                  props.primaryContactFields.length > 0 &&
                  props.primaryContactFields.includes(element.field) && (
                    <>
                      {" ("}
                      <Checkbox
                        checked={
                          props.primaryContact === element.field ? true : false
                        }
                        value={element.field}
                        onChange={(ev: any, data: any) => {
                          setCheckedData(data.checked);
                          props.setFieldAsPrimary(
                            data.checked ? ev.target.value : ""
                          );
                        }}
                        label="Set as primary"
                      />
                      {")"}
                    </>
                  )}
              </>
            }
            orientation={props.isAdminForm ? "horizontal" : "vertical"}
            validationState={formik.errors[fieldInfo.name] ? "error" : "none"}
            validationMessage={`${
              formik.errors[fieldInfo.name] !== undefined
                ? formik.errors[fieldInfo.name]
                : ""
            }`}
            // validationState="success"
          >
            <Input
              appearance="filled-lighter"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={fieldInfo.name}
              placeholder={fieldInfo.placeholder}
              disabled={fieldInfo.readOnly}
              value={
                formik?.values[fieldInfo.name] !== undefined
                  ? formik.values[fieldInfo.name]
                  : ""
              }
            />
            {/* {props.primaryContactFields !== undefined &&
              props.primaryContactFields.length > 0 &&
              props.primaryContactFields.includes(element.field) && (
                <Checkbox
                  checked={
                    props.primaryContact === element.field ? true : false
                  }
                  value={element.field}
                  onChange={(ev: any, data: any) => {
                    setCheckedData(data.checked);
                    props.setFieldAsPrimary(
                      data.checked ? ev.target.value : ""
                    );
                  }}
                  label="Set as primary contact"
                />
              )} */}
          </Field>
        );
        break;
    }
  };
  const [documentTitle, setDocumentTitle] = useState("");

  const downloadFile = (fileUrl: string, DocumentTitle: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = DocumentTitle;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = (fileId: string) => {
    apiServices.document
      .getDocument(fileId, props?.customer_id)
      .then((res) => {
        if (res.data && res.data.data) {
          const { fileUrl, DocumentTitle } = res.data.data;
          setDocumentTitle(res.data.data.DocumentTitle);
          downloadFile(fileUrl, DocumentTitle);
        }
      })
      .catch((err: any) => console.log(err));
  };
  const showElement = (element: any) => {
    const fieldInfo = {
      name: element.field,
      label: element.label,
    };
    if (element.type === "datepicker") {
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <Label>{formatedDate(formik.values[fieldInfo.name])}</Label>
        </div>
      );
    } else if (element.type === "text-editor") {
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <ReactQuillComponent value={formik.values[fieldInfo.name]} />
        </div>
      );
    } else if (element.type === "fileUpload") {
      // apiServices.notes.getById()
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <div>
            {formik.values.attachments &&
            formik.values.attachments.length > 0 ? (
              <>
                {formik.values.attachments.map((doc: any, index: number) => (
                  <div key={index}>
                    <span>{getFileNameFromUrl(doc?.DocumentTitle || "")}</span>
                    <span
                      onClick={() => handleDownload(doc.DocumentId)}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                        marginLeft: "10px",
                      }}
                    >
                      Download
                    </span>
                  </div>
                ))}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
      );
    } else if (element.type === "text-area") {
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <ShowMoreText
            /* Default options */
            lines={2}
            more="Show more"
            less="Show less"
            className="content-css"
            anchorClass="show-more-less-clickable"
            onClick={() => {}}
            expanded={false}
            width={280}
            truncatedEndingComponent={"... "}
          >
            {formik.values[fieldInfo.name]}
          </ShowMoreText>
        </div>
      );
    } else if (element.type === "toggle-button") {
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <Label>{formik.values[fieldInfo.name] === 0 ? "No" : "Yes"}</Label>
        </div>
      );
    } else if (element.type === "select") {
      const getFieldValue = () => {
        const fieldMapping: { [key: string]: any } = {
          consultant_id: formik.values.consultant,
          commission_type: formik.values.commission_type,
        };

        if (fieldInfo.name in fieldMapping) {
          return fieldMapping[fieldInfo.name];
        }

        if (["applicable", "pending"].includes(fieldInfo.name)) {
          return formik.values[fieldInfo.name] === 0 ? "No" : "Yes";
        }

        return formik.values[fieldInfo.name];
      };

      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <Label>{getFieldValue()}</Label>
        </div>
      );
    } else {
      return (
        <div className={classes.fieldWrapper}>
          <Label weight="semibold" size="medium">
            {element.label}
          </Label>
          <Label>
            {formik.values[fieldInfo.name]}
            {/* view mode code pls  */}
          </Label>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        {props?.mode && props?.mode === "view"
          ? showElement(props?.item)
          : getElement(props?.item)}
      </div>
      <Dialog modalType="non-modal" open={openTextEditor}>
        <DialogSurface aria-describedby={undefined}>
          <DialogBody>
            <DialogTitle>Dialog title</DialogTitle>
            <DialogContent>
              <TextEditor />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    setOpenTextEditor(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default RenderFormElements;

import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from "material-react-table";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";

interface ClientRow {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
}

function ClientSelection(props: any) {
  const [clients, setClients] = useState<ClientRow[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [loading, setLoading] = useState<boolean>(false);

  const columns = useMemo<MRT_ColumnDef<ClientRow>[]>(
    () => [
      {
        accessorKey: "first_names",
        header: "First Name",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
    ],
    []
  );

  async function getClientList() {
    setLoading(true);
    try {
      const resp = await apiServices.client.getClients(
        pagination.pageIndex + 1,
        pagination.pageSize,
        searchText
      );
      setRowCount(resp?.data?.data?.count || 0);
      // const transformedData = resp?.data?.data?.list?.map((client: any) => ({
      //   customer_id: client.customer_id,
      //   first_names: client?.NameAndAddress?.first_names || "",
      //   last_name: client?.NameAndAddress?.last_name || "",
      //   email: client?.Contact?.email || "",
      // })) || [];
      const transformedData = resp?.data.data?.list
        .filter((item: any) => item.Contact.email !== "") // Filter out objects with empty emails
        .map((client: any) => ({
          customer_id: client.customer_id,
          first_names: client?.NameAndAddress?.first_names || "",
          last_name: client?.NameAndAddress?.last_name || "",
          email: client?.Contact?.email || "",
        }));

      console.log(transformedData, "transformed data");
      setClients(transformedData);
      props.setClientList(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (Object.keys(rowSelection).length > 0 && props.handleClientSelection) {
      const selectedClients = clients.filter(
        (client) => rowSelection[client.customer_id]
      );
      props.handleClientSelection(selectedClients);
    }
  }, [rowSelection, clients, props]);
  useEffect(() => {
    getClientList();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  return (
    <div>
      <OverlayLoader isLoading={loading} />
      <div className="table-in-popup">
        <MaterialReactTable<ClientRow>
          columns={columns}
          data={clients}
          onRowSelectionChange={setRowSelection}
          onPaginationChange={setPagination}
          rowCount={rowCount}
          manualPagination
          state={{ pagination, rowSelection }}
          enableGlobalFilter
          enableColumnActions={false}
          enableColumnFilters
          enableRowSelection
          enableFullScreenToggle={false}
          getRowId={(row) => row.customer_id}
          enableMultiRowSelection
          muiTablePaginationProps={{
            showFirstButton: false,
            showLastButton: false,
          }}
          muiTableBodyCellProps={({ cell, row }) => {
            if (cell.column.id === "mrt-row-select") {
              return {
                sx: {
                  pointerEvents: row.original.email ? "auto" : "none",
                  opacity: row.original.email ? 1 : 0.5,
                },
                disabled: !row.original.email,
              };
            }
            return {
              style: {
                padding: "6px 12px",
                fontSize: "0.875rem",
              },
            };
          }}
          onGlobalFilterChange={setSearchText}
        />
      </div>
    </div>
  );
}

export default ClientSelection;

import { useFormik } from "formik";

interface InputParams {
  initialValues: {
    [key: string]: any;
  };
  enableReinitialize?: boolean;
  onSubmitFn: (values: { [key: string]: any }) => any;
  validate: (values: any) => any;
}

const FormikInputRequest = ({
  initialValues,
  // enableReinitialize = true,
  onSubmitFn,
  validate,
}: InputParams) =>
  useFormik({
    initialValues,
    // enableReinitialize,
    onSubmit: onSubmitFn,
    validate: validate,
  });

export default FormikInputRequest;

import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Button,
  Tab,
  TabList,
} from "@fluentui/react-components";
import apiServices from "../../service";
import OverlayLoader from "../../components/OverlayLoader";
enum TabValues {
  Client = "tab1",
  Partner = "tab2",
}
const ChangesCP = ({
  NextToDataSelection,
  BackToSecond,
  CancelCP,
  ClientPartner,
  partnerID,
  getPartner,
  setGetPartner
}: {
  NextToDataSelection: () => void;
  BackToSecond: () => void;
  CancelCP: () => void;
  ClientPartner: any;
  partnerID: any;
  getPartner: any;
  setGetPartner: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [selectedTab, setSelectedTab] = useState<TabValues>(TabValues.Client);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [openItems, setOpenItems] = React.useState(["1"]);
  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };
  const [loading,setIsLoading]=useState<boolean>(false)

  const getPartnerDetails = async () => {
    const id = partnerID;
  
    try {
      setIsLoading(true);
      const res = await apiServices.ClientPartnerSplit.getClientDetail(id);
      setGetPartner(res.data.data);
    } catch (error) {
      console.error("Error fetching client details:", error);
    } finally {
      setIsLoading(false);
      console.log("Client detail fetch attempt completed.");
    }
  };
  
  useEffect(() => {
    getPartnerDetails();
  }, [partnerID]); 
  
  return (
    <>
    <OverlayLoader isLoading={loading}/>
      <div className="container">
        <div className="sub-heading">
          <div>
            <span className="headings">Changed Client/Partner Details</span>
          </div>
          <div>
            <span className="description">
              Client-Assyst will make these changes to the original
              client/partner records and add a new Address record to the
              database.
            </span>
          </div>
          <div>
            <TabList
              defaultSelectedValue={TabValues.Client}
              onTabSelect={(event, data) => {
                setSelectedTab(data.value as TabValues);
                setSelectedItem(null);
              }}
            >
              {" "}
              <Tab value={TabValues.Client}>
                <div className="tab-sub-title">
                  <div>
                    <span >
                      {ClientPartner?.NameAndAddress?.first_names || ""}
                    </span>{" "}
                  </div>
                  <div style={{ fontSize: "12px", fontWeight: 400 }}>Client</div>
                </div>
              </Tab>
              <Tab value={TabValues.Partner}>
                <div className="tab-sub-title">
                  <div>
                    <span >
                    {getPartner?.NameAndAddress?.first_names || ""}
                    </span>{" "}
                  </div>
                  <div style={{ fontSize: "12px", fontWeight: 400 }}>Partner </div>
                </div>
              </Tab>
            </TabList>
          </div>
          <div
            style={{
              // display: "flex",
              // justifyContent: "stretch",
              padding: "3px",
              minHeight: "160px",
            }}
          >
            {selectedTab === TabValues.Client && (
              <div className="tab-section">
                <div className="tab-heading">
                  <div></div>
                  <div>
                    <span className="bolds">Current</span>
                  </div>
                  <div>
                    <span className="bolds">Will Change To</span>
                  </div>
                </div>
                {ClientPartner ? (
                  <div className="accordion-section">
                    <Accordion
                      openItems={openItems}
                      onToggle={handleToggle}
                      multiple
                      collapsible
                    >
                      <AccordionItem value="1">
                        <AccordionHeader>
                          <span className="main-sub-title">
                            Altered Name & Address
                          </span>{" "}
                        </AccordionHeader>
                        <AccordionPanel as="div">
                          <div className="border-section">
                            <div className="border-inner-style">
                              {" "}
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">First Name </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.first_names}
                                </div>
                                <div>
                                  {" "}
                                  {ClientPartner?.NameAndAddress?.first_names}
                                </div>
                              </div>
                              {/* ///////////////////////// */}
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Initials </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.initials}
                                </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.initials}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Last Name </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.last_name}
                                </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.last_name}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Address Name{" "}
                                </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.address_name}
                                </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.address_name}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Street Address
                                </div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Town</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">County</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Country</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Postcode</div>
                                <div>****************</div>
                                <div>****************</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Date Moved In
                                </div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Salutation</div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.salutation}
                                </div>
                                <div>
                                  {ClientPartner?.NameAndAddress?.salutation}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Joint Salutation
                                </div>
                                <div>
                                  {
                                    ClientPartner?.NameAndAddress
                                      .joint_salutation
                                  }
                                </div>
                                <div>
                                  {
                                    ClientPartner?.NameAndAddress
                                      .joint_salutation
                                  }
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Joint Address Name
                                </div>
                                <div>
                                  {
                                    ClientPartner?.NameAndAddress
                                      .joint_address_label
                                  }
                                </div>
                                <div>
                                  {
                                    ClientPartner?.NameAndAddress
                                      .joint_address_label
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem value="2">
                        <AccordionHeader>
                          <b>Altered Client Type</b>
                        </AccordionHeader>
                        <AccordionPanel>
                          <div className="border-section">
                            <div className="border-inner-style"></div>{" "}
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Client Type </div>
                              <div>
                                {ClientPartner?.Administration?.client_type}
                              </div>
                              <div>
                                {" "}
                                {ClientPartner?.Administration?.client_type}
                              </div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">
                                Other Client Type{" "}
                              </div>
                              <div>
                                {ClientPartner?.Administration?.fsa_client_type}
                              </div>
                              <div>
                                {" "}
                                {ClientPartner?.Administration?.fsa_client_type}
                              </div>
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem value="3">
                        <AccordionHeader>
                          <b>
                            Original address to be added to the Address category
                          </b>
                        </AccordionHeader>
                        <AccordionPanel>
                          <div className="border-section">
                            <div className="border-inner-style">
                              {" "}
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Address Type{" "}
                                </div>
                                <div></div>
                                <div>Values</div>
                              </div>
                              {/* ///////////////////// */}
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Street Address
                                </div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Town</div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">County</div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Country</div>
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Postcode</div>
                                <div></div>
                                <div>****************</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Home Phone</div>
                                <div></div>
                                <div>{ClientPartner?.Contact.home_phone}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Work Phone</div>
                                <div></div>
                                <div>{ClientPartner?.Contact.work_phone}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Mobile</div>
                                <div></div>
                                <div>{ClientPartner?.Contact.mobile}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Email</div>
                                <div></div>
                                <div>{ClientPartner?.Contact.email}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Work Email</div>
                                <div></div>
                                <div>{ClientPartner?.Contact.work_email}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Date Moved In
                                </div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {selectedTab === TabValues.Partner && (
              <div className="tab-section">
                <div className="tab-heading">
                  <div></div>
                  <div>
                    <span className="bolds">Current</span>
                  </div>
                  <div>
                    <span className="bolds">Will Change To</span>
                  </div>
                </div>
                {getPartner ? (
                  <div className="accordion-section">
                    <Accordion
                      openItems={openItems}
                      onToggle={handleToggle}
                      multiple
                      collapsible
                    >
                      <AccordionItem value="1">
                        <AccordionHeader>
                          <b>Altered Name & Address</b>
                        </AccordionHeader>
                        <AccordionPanel as="div">
                          <div className="border-section">
                            <div className="border-inner-style">
                              {" "}
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">First Name</div>
                                <div>
                                  {getPartner?.NameAndAddress?.first_names}
                                </div>
                                <div>
                                  {getPartner?.NameAndAddress?.first_names}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Initials </div>
                                <div>{getPartner?.NameAndAddress?.initials}</div>
                                <div>{getPartner?.NameAndAddress?.initials}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Last Name </div>
                                <div>{getPartner?.NameAndAddress?.last_name}</div>
                                <div>{getPartner?.NameAndAddress?.last_name}</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Address Name{" "}
                                </div>
                                <div>
                                  {getPartner?.NameAndAddress?.address_name}
                                </div>
                                <div>
                                  {getPartner?.NameAndAddress?.address_name}
                                </div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Street Address
                                </div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Town</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">County</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Country</div>
                                <div className="not-use">**DO NOT USE**</div>
                                <div className="not-use">**DO NOT USE**</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Postcode</div>
                                <div>****************</div>
                                <div>****************</div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">
                                  Date Moved In
                                </div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <div className="tab-content">
                                {" "}
                                <div className="title-section">Salutation</div>
                                <div>
                                  {getPartner?.NameAndAddress?.salutation}
                                </div>
                                <div>
                                  {getPartner?.NameAndAddress?.salutation}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem value="2">
                        <AccordionHeader>
                          <b>
                            Original address to be added to the Address category
                          </b>
                        </AccordionHeader>
                        <AccordionPanel>
                          <div className="border-section">
                            <div className="border-inner-style"></div>{" "}
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Address Type </div>
                              <div></div>
                              <div>Values</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">
                                Street Address
                              </div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div></div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div></div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Town</div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">County</div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Country</div>
                              <div></div>
                              <div className="not-use">**DO NOT USE**</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Postcode</div>
                              <div></div>
                              <div>****************</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Home Phone</div>
                              <div></div>
                              <div>{getPartner?.Contact.home_phone}</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Work Phone</div>
                              <div></div>
                              <div>{getPartner?.Contact.work_phone}</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Mobile</div>
                              <div></div>
                              <div>{getPartner?.Contact.mobile}</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Email</div>
                              <div></div>
                              <div>{getPartner?.Contact.email}</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Work Email</div>
                              <div></div>
                              <div>{getPartner?.Contact.work_email}</div>
                            </div>
                            <div className="tab-content">
                              {" "}
                              <div className="title-section">Date Moved In</div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="buttons">
        <div>
          <Button size="small" onClick={BackToSecond}>
            Back
          </Button>
        </div>
        <div>
          {" "}
          <Button
            size="small"
            appearance="primary"
            onClick={NextToDataSelection}
          >
            Next
          </Button>
        </div>
        <div>
          {" "}
          <Button onClick={CancelCP} size="small">
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangesCP;

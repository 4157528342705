import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  Select,
  Checkbox,
  Button,
  makeStyles,
  shorthands,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import apiServices from "../service";
import { size } from "lodash";
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "75vh", // Adjust as needed
    width: "100%",
    backgroundColor: "#fff",
  },
  header: {
    padding: "16px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f4f4f4",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  content: {
    flex: "1",
    overflowY: "auto",
  },

  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#f9f9f9",
  },
  headerCell: {
    backgroundColor: "#2a5283",
    color: "white",
    fontWeight: "bold",
    textAlign: "left",
    padding: "6px",
  },
  bodyCell: {
    border: "1px solid #ddd",
    textAlign: "left",
    padding: "4px",
  },
  row: {
    ":nth-child(even)": {
      backgroundColor: "#f2f2f2",
    },
    ":hover": {
      backgroundColor: "#ddd",
      cursor: "pointer",
    },
  },
  noDataCell: {
    textAlign: "center",
  },
});
const ModalDataHandler = (props: any) => {
  const classes = useStyles();
  const [columns, setColumns] = useState<{ [key: string]: any }[]>([]);
  const [dataList, setDataList] = useState<{ [key: string]: any }[]>([]);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [apiProcessing, setApiProcessing] = useState(false);
  const [ratingList, setRatingList] = useState<{ [key: string]: any }[]>([]);
  const [selectValues, setSelectValues] = useState<any[]>([]);
  const columnDefinitions: any = {
    attitudeToRisk: [
      { columnKey: "check", label: "" },
      { columnKey: "category", label: "Category" },
      { columnKey: "rating", label: "Rating" },
    ],
    income_categories: [
      { columnKey: "check", label: "" },
      { columnKey: "sort_order", label: "Sortorder" },
      { columnKey: "description", label: "Description" },
      { columnKey: "frequency", label: "Frequency" },
    ],
    outgoings: [
      { columnKey: "check", label: "" },
      { columnKey: "sort_order", label: "Sortorder" },
      { columnKey: "description", label: "Description" },
      { columnKey: "discretionary", label: "Type" },
      { columnKey: "frequency", label: "Frequency" },
    ],
    objectives: [
      { columnKey: "check", label: "" },
      { columnKey: "sort_order", label: "Sortorder" },
      { columnKey: "detail", label: "Detail" },
    ],
  };

  useEffect(() => {
    setCheckedItems([]);
    if (props.activeModalDataItem !== "") {
      setColumns(columnDefinitions[props.activeModalDataItem] || []);
      fetchDataList();
    }
  }, [props.activeModalDataItem, ratingList]);

  const formatData = async (data: any, category: string) => {
    let mappedArray = [];
    if (category === "income_categories") {
      mappedArray = data.map((data: any, index: number) => ({
        sort_order: data.sort_order,
        description: data.description,
        frequency: data.frequency,
        checkBox: (
          <Checkbox
            value={Object.values({
              income_category_id: data.id,
            })}
            onChange={() => handleCheckboxIncome(data.id)}
          />
        ),
      }));
    } else if (category === "outgoings") {
      mappedArray = data.map((data: any, index: number) => {
        return {
          sort_order: data.sort_order,
          description: data.description,
          frequency: data.frequency,
          discretionary: data.discretionary,
          checkBox: (
            <Checkbox
              value={Object.values({
                expense_category_id: data.id,
              })}
              onChange={() => handleCheckboxExpense(data.id)}
            />
          ),
        };
      });
    } else if (category === "objectives") {
      mappedArray = data.map((data: any, index: number) => {
        return {
          sort_order: data.sort_order,
          detail: data.detail,
          checkBox: (
            <Checkbox
              value={Object.values({
                detail_id: data.id,
              })}
              onChange={() => handleCheckbox(data.id)}
            />
          ),
        };
      });
    } else if (category === "attitudeToRisk") {
      mappedArray = data.map((data: any, index: number) => {
        return {
          category: data.category,
          rating: (
            <Select
              onChange={(e) => onRatingChange(data, index, e.target.value)}
              // value={selectValues[index]}
              id={data.id}
            >
              <option value=""> Select Rating </option>
              {ratingList.map((option: any) => (
                <option
                  key={option.id}
                  value={option.id}
                  label={option.rating}
                />
              ))}
            </Select>
          ),
          checkBox: (
            <Checkbox
              value={Object.values({
                category_id: data.id,
                rating_id: selectValues,
              })}
              onChange={() =>
                handleCheckboxChange(data.id, selectValues[index])
              }
            />
          ),
        };
      });
    }

    return mappedArray;
  };
  const onRatingChange = (data: any, index: any, value: any) => {
    setSelectValues((prevSelectValues: any) => {
      // Get the previous value for this index
      const previousValue = prevSelectValues[index];

      // Update selectValues with the new value
      const updatedValues = [...prevSelectValues]; // Clone the array
      updatedValues[index] = value;

      // Update checkedItems if necessary
      if (previousValue !== value) {
        setCheckedItems((prevCheckedItems: any) => {
          // Find the existing item with the same category_id
          const existingItemIndex = prevCheckedItems.findIndex(
            (item: any) =>
              item.category_id === data.id && item.rating_id === previousValue
          );

          if (existingItemIndex !== -1) {
            // If the previous value exists, we need to update or remove it
            if (value === "") {
              // If the new value is empty, remove the item
              return prevCheckedItems.filter(
                (item: any) =>
                  item.category_id !== data.id ||
                  item.rating_id !== previousValue
              );
            } else {
              // Update the item with the new rating_id
              const updatedItems = [...prevCheckedItems];
              updatedItems[existingItemIndex] = {
                category_id: data.id,
                rating_id: value,
              };
              return updatedItems;
            }
          } else if (value !== "") {
            // If the previous value was not found and the new value is not empty, add it
            return [
              ...prevCheckedItems,
              { category_id: data.id, rating_id: value },
            ];
          }

          // If nothing changes, return the previous state
          return prevCheckedItems;
        });
      }

      return updatedValues; // Return the updated selectValues
    });
  };
  const handleCheckboxChange = (dataId: any, ratingId: any) => {
    setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) =>
          item.category_id === dataId && item.rating_id === ratingId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter(
          (item: any) =>
            !(item.category_id === dataId && item.rating_id === ratingId)
        );
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { category_id: dataId, rating_id: ratingId }];
      }
    });
  };
  const handleCheckbox = (dataId: any) => {
    setCheckedItems((prev: []) => {
      const isChecked = prev.some((item: any) => item.detail_id === dataId);

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.detail_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { detail_id: dataId }];
      }
    });
  };
  const handleCheckboxExpense = (dataId: any) => {
    setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) => item.expense_category_id === dataId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.expense_category_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { expense_category_id: dataId }];
      }
    });
  };
  const handleCheckboxIncome = (dataId: any) => {
    setCheckedItems((prev: []) => {
      const isChecked = prev.some(
        (item: any) => item.income_category_id === dataId
      );

      if (isChecked) {
        // If the item is already checked, remove it from the array
        return prev.filter((item: any) => item.income_category_id !== dataId);
      } else {
        // If the item is not checked, add it to the array
        return [...prev, { income_category_id: dataId }];
      }
    });
  };

  const fetchDataList = async () => {
    if (props.activeModalDataItem === "income_categories") {
      try {
        const response =
          await apiServices.systemManager.incomeCategory.getList();
        if (!response.data.isError && response.data.data) {
          const result = await formatData(
            response.data.data,
            props.activeModalDataItem
          );

          setDataList(result);
          setIsContentLoading(false);
        }
      } catch (err) {
        setIsContentLoading(false);
        console.error(err);
      }
    } else if (props.activeModalDataItem === "outgoings") {
      try {
        const response =
          await apiServices.systemManager.expenseCategory.getList();
        if (!response.data.isError && response.data.data) {
          const result = await formatData(
            response.data.data,
            props.activeModalDataItem
          );

          setDataList(result);
          setIsContentLoading(false);
        }
      } catch (err) {
        setIsContentLoading(false);
        console.error(err);
      }
    } else if (props.activeModalDataItem === "objectives") {
      try {
        const response = await apiServices.systemManager.objectives.getList();
        if (!response.data.isError && response.data.data) {
          const result = await formatData(
            response.data.data,
            props.activeModalDataItem
          );

          setDataList(result);
          setIsContentLoading(false);
        }
      } catch (err) {
        setIsContentLoading(false);
        console.error(err);
      }
    } else if (props.activeModalDataItem === "attitudeToRisk") {
      try {
        const [attitudeRatingResponse, attitudeRiskCategoryResponse] =
          await Promise.all([
            apiServices.systemManager.attitudeToRiskRating.getList(),
            apiServices.systemManager.attitudeToRiskcategory.getList(),
          ]);

        if (
          !attitudeRatingResponse.data.isError &&
          attitudeRatingResponse.data.data
        ) {
          setRatingList(attitudeRatingResponse.data.data);
        }
        if (
          !attitudeRiskCategoryResponse.data.isError &&
          attitudeRiskCategoryResponse.data.data
        ) {
          const formattedRiskData = await formatData(
            attitudeRiskCategoryResponse.data.data,
            "attitudeToRisk"
          );
          setDataList(formattedRiskData);
        }
        setIsContentLoading(false);
      } catch (err) {
        setIsContentLoading(false);
        console.error(err);
      }
    } else {
      setIsContentLoading(false);
    }
  };

  const tableBody = () => {
    if (dataList.length > 0) {
      if (props.activeModalDataItem === "attitudeToRisk") {
        return dataList.map((items: any, index: number) => (
          <TableRow key={`${items.category}-${index}`}>
            <TableCellLayout media={items.checkBox}></TableCellLayout>

            <TableCell>
              <TableCellLayout>{items.category}</TableCellLayout>
            </TableCell>
            <TableCell>{items.rating}</TableCell>
          </TableRow>
        ));
      } else if (props.activeModalDataItem === "income_categories") {
        return dataList.map((items: any) => (
          <TableRow key={items.id} className={classes.row}>
            <TableCellLayout media={items.checkBox}></TableCellLayout>
            <TableCell>
              <TableCellLayout>{items.sort_order}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{items.description}</TableCellLayout>
            </TableCell>

            <TableCell>
              <TableCellLayout>{items.frequency}</TableCellLayout>
            </TableCell>
          </TableRow>
        ));
      } else if (props.activeModalDataItem === "outgoings") {
        return dataList.map((items: any) => (
          <TableRow key={items.id}>
            <TableCellLayout media={items.checkBox}></TableCellLayout>

            <TableCell>
              <TableCellLayout>{items.sort_order}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{items.description}</TableCellLayout>
            </TableCell>

            <TableCell>
              <TableCellLayout>{items.discretionary}</TableCellLayout>
            </TableCell>

            <TableCell>
              <TableCellLayout>{items.frequency}</TableCellLayout>
            </TableCell>
          </TableRow>
        ));
      } else if (props.activeModalDataItem === "objectives") {
        return dataList.map((items: any) => (
          <TableRow key={items.id}>
            <TableCellLayout media={items.checkBox}></TableCellLayout>
            <TableCell>
              <TableCellLayout>{items.sort_order}</TableCellLayout>
            </TableCell>
            <TableCell>
              <TableCellLayout>{items.detail}</TableCellLayout>
            </TableCell>
          </TableRow>
        ));
      } else
        return (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              <p className={classes.noDataCell}>No data found</p>
            </TableCell>
          </TableRow>
        );
    } else {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <p className={classes.noDataCell}>No data found</p>
          </TableCell>
        </TableRow>
      );
    }
  };

  const handleApply = () => {
    if (checkedItems.length > 0) {
      setApiProcessing(true);
      if (props.activeModalDataItem === "income_categories") {
        let reqBody = {
          income: {
            customer_id: props.clientId,
            income_categories: checkedItems,
          },
        };
        apiServices.getDefaultIncome
          .add(reqBody)
          .then((response: any) => {
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            console.log(e);
            props.notify(e.message, "", "error");
            setApiProcessing(false);
          });
      } else if (props.activeModalDataItem === "outgoings") {
        let reqBody = {
          outgoings: {
            customer_id: props.clientId,
            expense_categories: checkedItems,
          },
        };
        apiServices.getDefaultexpense
          .add(reqBody)
          .then((response: any) => {
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            console.log(e);
            props.notify(e.message, "", "error");
            setApiProcessing(false);
          });
      } else if (props.activeModalDataItem === "objectives") {
        let reqBody = {
          Objective: {
            customer_id: props.clientId,
            objectives: checkedItems,
          },
        };
        apiServices.getDefaultobjective
          .add(reqBody)
          .then((response: any) => {
            handleApiSuccessResponse(response);
          })
          .catch((e: Error) => {
            console.log(e);
            props.notify(e.message, "", "error");
            setApiProcessing(false);
          });
      } else if (props.activeModalDataItem === "attitudeToRisk") {
        let reqBody = {
          attitude_to_risk: {
            customer_id: props.clientId,
            attitude_risks: checkedItems,
          },
        };
        let hasEmptyRating = checkedItems.some(
          (item: any) => item.rating_id === "" || item.rating_id === undefined
        );
        if (hasEmptyRating) {
          props.notify(
            "One or more 'rating' values are empty. Please fill rating.",
            "",
            "error"
          );
          setApiProcessing(false);
        } else {
          apiServices.getDefaultAttitudeToRisk
            .add(reqBody)
            .then((response: any) => {
              handleApiSuccessResponse(response);
            })
            .catch((e: Error) => {
              console.log(e);
              props.notify(e.message, "", "error");
              setApiProcessing(false);
            });
        }
      }
    } else {
      props.notify("One or more items are required", "", "error");
    }
  };
  const handleApiSuccessResponse = (response: any) => {
    setApiProcessing(false);
    props.reloadItemList();
    const { data } = response;

    if (!data.isError) {
      setTimeout(() => {
        setCheckedItems([]);
        props.notify(data.message, "", "success");
      }, 1000);
      props.reloadItemList();
      props.setLoadMasterData(false);
    } else if (data.isError && data.error && Object.keys(data.error).length) {
      const errorMessage = Array.isArray(data.error.message)
        ? data.error.message.join(", ")
        : data.error.message;
      props.notify(errorMessage, "", "error");
    } else {
      props.notify("Something went wrong", "", "error");
    }
  };

  return (
    <>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.content}>
            {isContentLoading ? (
              <div className={classes.loaderContainer}>
                <Spinner label="Loading..." labelPosition="below" />
              </div>
            ) : (
              <>
                {columns.length > 0 && (
                  <Table className={classes.table}>
                    <TableHeader as="thead">
                      <TableRow>
                        {columns.map((column) => (
                          <TableHeaderCell
                            key={column.columnKey}
                            className={classes.headerCell}
                          >
                            {column.label}
                          </TableHeaderCell>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                )}
              </>
            )}
          </div>
        </div>
      </DialogContent>
      {!isContentLoading && (
        <DialogActions>
          <Button
            className="asc-button-primary"
            appearance="primary"
            size="small"
            icon={apiProcessing ? <Spinner size="tiny" /> : null}
            onClick={() => {
              handleApply();
            }}
          >
            {apiProcessing ? "Processing..." : "Apply"}
          </Button>
          <Button
            appearance="secondary"
            size="small"
            onClick={() => {
              props.setLoadMasterData(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </>
  );
};
export default ModalDataHandler;

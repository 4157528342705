import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioGroup,
  makeStyles,
  tokens,
  shorthands,
  OverlayDrawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody,
  useToastController,
  useId,
  Toast,
  ToastTitle,
  ToastTrigger,
  Link,
  ToastBody,
  Toaster,
  Spinner,
} from "@fluentui/react-components";
import {
  Add20Filled,
  Add16Filled,
  ChevronLeft20Regular,
  Add20Regular,
  DocumentBulletList20Regular,
  DocumentFolder20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import CaseNavList from "./CaseNavList";
import apiServices from "../../../service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  banksBSocieties,
  homePersonal,
  shares,
  unitLinked,
} from "../../../shared/AssetDetails";
import {
  CaseCategoryMap,
  caseContextList,
  checkIfKeyExist,
  formatedDate,
  formatedDate2,
  getCaseCategoryByCaseType,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import CaseForm from "./CaseForm";
import CaseList from "./CaseList";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import LoadStandardAction from "../../../components/LoadStandardAction";
import ContentLoader from "../../../components/Loader/ContentLoader";
import { TableListLoader } from "../../../components/Loader/TableListLoader";
import { reloadData, reloadDetails } from "../../../redux/appSettingsSlice";
import {
  getAssetActionDetailById,
  getAssetActionsListItems,
  getAssetFundDetailById,
  getAssetFundsListItems,
  getAssetPaymentDetailById,
  getAssetPaymentsCommissionDetailById,
  getAssetPaymentsCommissionsListItems,
  getAssetPaymentsListItems,
  getAssetValuationDetailById,
  getAssetValuationsListItems,
  getAssetWithdrawalDetailById,
  getAssetWithdrawalsListItems,
} from "../../../redux/Case/assetSlice";
import {
  getLiabilityActionDetailById,
  getLiabilityActionsListItems,
  getLiabilityPaymentDetailById,
  getLiabilityPaymentsListItems,
} from "../../../redux/Case/liabilitySlice";
import {
  getPolicyActionDetailById,
  getPolicyActionsListItems,
  getPolicyFundDetailById,
  getPolicyFundsListItems,
  getPolicyPaymentDetailById,
  getPolicyPaymentsListItems,
  getPolicyValuationDetailById,
  getPolicyValuationsListItems,
  getPolicyWithdrawalDetailById,
  getPolicyWithdrawalsListItems,
} from "../../../redux/Case/policySlice";
import {
  CreditCards,
  LoansLeaseHP,
  Mortgages,
} from "../../../shared/LiabilityDetails";
import {
  General,
  HealthAssurance,
  IncomeProtection,
  Investments,
  lifeAssurance,
  pension,
  SavingsPlan,
} from "../../../shared/policyDetails";
const useStyles = makeStyles({
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    marginTop: "10px",
    marginRight: "10px",
  },
  emptyState: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
});
const CaseSubItemDetailPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const styles = useStyles();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const { clientId, action, caseId, subItemId, subSubItemId } = useParams();
  const [caseSubItemList, setCaseSubItemList] = useState<any>([]);
  const [caseSubSubItemList, setCaseSubSubItemList] = useState<any>([]);
  const [caseDetail, setCaseDetail] = useState<any>({});
  const [subItemDetail, setSubItemDetail] = useState<any>({});
  const [subSubItemDetail, setSubSubItemDetail] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(true);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const assets = useSelector((state: RootState) => state.assets);
  const liabilities = useSelector((state: RootState) => state.liabilities);
  const policies = useSelector((state: RootState) => state.policies);
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [commissionDistributionList, setCommissionDistributionList] =
    useState<any>([]);
  const [distributionDetail, setDistributionDetail] = useState<any>({});
  const [caseType, setCaseType] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("");
  const [fundValue, setFundValue] = useState<any>();
  const [paymentValue, setPaymentValue] = useState<any>();
  const [withdrawalValue, setWithdrawalValue] = useState<any>();
  const dispatch: AppDispatch = useDispatch();
  const isReloadDataList = useSelector(
    (state: RootState) => state.appSettings.reloadDataList
  );
  const isReloadDetails = useSelector(
    (state: RootState) => state.appSettings.reloadDetails
  );
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);

  useEffect(() => {
    if (props.subItem) {
      if (props.section && props.section == "assets" && assets) {
        if (assets && props.subItem) {
          setCaseDetail(assets.activeItem);
          if (props.subItem == "valuations") {
            setCaseSubItemList(assets.valuations.listItems);
            setListLoading(false);
            setSubItemDetail(assets.valuations.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "actions") {
            setCaseSubItemList(assets.actions.listItems);
            setListLoading(false);
            setSubItemDetail(assets.actions.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "withdrawals") {
            setCaseSubItemList(assets.withdrawals.listItems);
            setWithdrawalValue(assets.withdrawals.totalWithdrawals);
            setListLoading(false);
            setSubItemDetail(assets.withdrawals.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "funds") {
            setCaseSubItemList(assets.funds.listItems);
            setFundValue(assets.funds.totalFund);
            setListLoading(false);
            setSubItemDetail(assets.funds.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "payments") {
            if (activeTab == "commission") {
              setCaseSubSubItemList(assets.payments.commissions.listItems);

              setListLoading(false);
              setSubItemDetail(assets.payments.commissions.activeItem);
              setDetailLoading(false);
            } else {
              setCaseSubItemList(assets.payments.listItems);
              setPaymentValue(assets.payments.totalPayment);
              setListLoading(false);
              setSubItemDetail(assets.payments.activeItem);
              setDetailLoading(false);
            }
          }
        }
      } else if (
        props.section &&
        props.section == "liabilities" &&
        liabilities
      ) {
        if (liabilities && props.subItem) {
          setCaseDetail(liabilities.activeItem);
          if (props.subItem == "actions") {
            setCaseSubItemList(liabilities.actions.listItems);
            setListLoading(false);
            setSubItemDetail(liabilities.actions.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "payments") {
            if (activeTab == "commission") {
              setCaseSubSubItemList(liabilities.payments.commissions.listItems);

              setListLoading(false);
              setSubItemDetail(liabilities.payments.commissions.activeItem);
              setDetailLoading(false);
            } else {
              setCaseSubItemList(liabilities.payments.listItems);
              setPaymentValue(liabilities.payments.totalPayment);
              setListLoading(false);
              setSubItemDetail(liabilities.payments.activeItem);
              setDetailLoading(false);
            }
          }
        }
      } else if (props.section && props.section == "policies" && policies) {
        if (policies && props.subItem) {
          setCaseDetail(policies.activeItem);
          if (props.subItem == "valuations") {
            setCaseSubItemList(policies.valuations.listItems);
            setListLoading(false);
            setSubItemDetail(policies.valuations.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "actions") {
            setCaseSubItemList(policies.actions.listItems);
            setListLoading(false);
            setSubItemDetail(policies.actions.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "withdrawals") {
            setCaseSubItemList(policies.withdrawals.listItems);
            setWithdrawalValue(policies.withdrawals.totalWithdrawals);
            setListLoading(false);
            setSubItemDetail(policies.withdrawals.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "funds") {
            setCaseSubItemList(policies.funds.listItems);
            setFundValue(policies.funds.totalFund);
            setListLoading(false);
            setSubItemDetail(policies.funds.activeItem);
            setDetailLoading(false);
          } else if (props.subItem == "payments") {
            if (activeTab == "commission") {
              setCaseSubSubItemList(policies.payments.commissions.listItems);

              setListLoading(false);
              setSubItemDetail(policies.payments.commissions.activeItem);
              setDetailLoading(false);
            } else {
              setCaseSubItemList(policies.payments.listItems);
              setPaymentValue(policies.payments.totalPayment);
              setListLoading(false);
              setSubItemDetail(policies.payments.activeItem);
              setDetailLoading(false);
            }
          }
        }
      }
    }
  }, [
    assets,
    liabilities,
    policies,
    props.subItem,
    activeTab,
    props.section,
    dispatch,
  ]);
  // useEffect(() => {
  //   if (
  //     isReloadDataList === true &&
  //     caseId != undefined &&
  //     clientId != undefined
  //   ) {
  //     fetchSubItemList();
  //   }
  // }, [isReloadDataList, caseId, clientId, dispatch]);
  // useEffect(() => {
  //   if (isReloadDetails === true && subItemId != undefined) {
  //     fetchSubItemDetail(subItemId);
  //   }
  // }, [isReloadDetails, dispatch]);

  useEffect(() => {
    if (Object.keys(caseDetail).length !== 0) {
      setCaseType(getCaseCategoryByCaseType(caseDetail["case_type"]));
    }
  }, [caseDetail]);
  useEffect(() => {
    if (caseId != undefined && clientId != undefined) {
      fetchSubItemList();
      fetchCaseDetail(caseId);
    }
  }, [caseId, clientId, props.subItem]);

  useEffect(() => {
    if (props.subItem != undefined) {
      switch (props.subItem) {
        case "funds":
          if (props.tab != undefined) {
            setActiveTab(props.tab);
          } else {
            setActiveTab("fund");
          }

          break;
        case "payments":
          if (props.tab != undefined) {
            setActiveTab(props.tab);
          } else {
            setActiveTab("payment");
          }

          break;
        case "withdrawals":
          if (props.tab != undefined) {
            setActiveTab(props.tab);
          } else {
            setActiveTab("withdrawal");
          }

          break;
        case "actions":
          if (props.tab != undefined) {
            setActiveTab(props.tab);
          } else {
            setActiveTab("action");
          }

          break;
        case "valuations":
          if (props.tab != undefined) {
            setActiveTab(props.tab);
          } else {
            setActiveTab("valuation");
          }

          break;
        default:
          setActiveTab("");
          break;
      }
    }
  }, [props.subItem, props.tab]);
  useEffect(() => {
    if (subItemId != undefined) {
      fetchSubItemDetail(subItemId);
    }
  }, [subItemId, activeTab]);
  useEffect(() => {
    if (subSubItemId != undefined) {
      fetchSubSubItemDetail(subSubItemId);
    }
  }, [subSubItemId, activeTab]);
  useEffect(() => {
    if (caseSubItemList.length > 0) {
      if (props.subItem === "funds") {
        navigate(
          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${
            caseSubItemList[0]?.fund_id
          }/fund/${action || "view"}`
        );
      } else if (props.subItem === "payments") {
        if (props.tab == "commission") {
          if (caseSubSubItemList.length > 0) {
            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${
                props.subItem
              }/${caseSubItemList[0]?.payment?.payment_id}/commission/${
                caseSubSubItemList[0].commission_id
              }/commission/${action || "view"}`
            );
          } else {
            navigate(
              `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${caseSubItemList[0]?.payment?.payment_id}/commission`
            );
          }
        } else {
          navigate(
            `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${
              caseSubItemList[0]?.payment?.payment_id
            }/${props.tab}/${action || "view"}`
          );
        }
      } else if (props.subItem === "withdrawals") {
        navigate(
          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${
            caseSubItemList[0]?.withdrawal_id
          }/withdrawal/${action || "view"}`
        );
      } else if (props.subItem === "actions") {
        navigate(
          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${
            caseSubItemList[0]?.business_id
          }/action/${action || "view"}`
        );
      } else if (props.subItem === "valuations") {
        navigate(
          `/clients/${clientId}/${props.section}/${caseId}/${props.subItem}/${
            caseSubItemList[0]?.valuation_id
          }/valuation/${action || "view"}`
        );
      }
    }
  }, [caseSubItemList, caseSubSubItemList]);
  useEffect(() => {
    if (Object.keys(subItemDetail).length !== 0) {
      if (props.tab == "commission" && subItemDetail.payment) {
        getCommissionList(subItemDetail.payment?.payment_id);
      }
    }
  }, [subItemDetail, props.tab]);

  const fetchCaseDetail = (id: string) => {
    if (props.section == "assets") {
      apiServices.assets
        .getDetail(id, clientId)
        .then((response) => {
          setLoading(false);

          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    } else if (props.section == "liabilities") {
      apiServices.liability
        .getDetail(id, clientId)
        .then((response: any) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    } else if (props.section == "policies") {
      apiServices.policy
        .getDetail(id, clientId)
        .then((response: any) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setCaseDetail(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    }
  };
  const fetchSubItemList = () => {
    // setLoading(true);
    // setListLoading(true);
    // setCaseSubItemList([]);
    if (props.subItem === "funds" && props.section) {
      if (caseId && clientId) {
        if (props.section == "assets") {
          dispatch(
            getAssetFundsListItems({ id: caseId, customerId: clientId })
          );
        } else {
          dispatch(
            getPolicyFundsListItems({ id: caseId, customerId: clientId })
          );
        }
      }
      // apiServices.fund
      //   .getList(clientId, caseId)
      //   .then((response) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     dispatch(reloadData(false));
      //     if (response.data) {
      //       if (response.data.data && response.data.data.result) {
      //         setCaseSubItemList(response.data.data?.result || []);
      //         setFundValue(response.data.data?.total_fund);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     console.log(err);
      //   });
    } else if (props.subItem === "payments" && props.section) {
      if (caseId && clientId) {
        if (props.section == "assets") {
          dispatch(
            getAssetPaymentsListItems({ id: caseId, customerId: clientId })
          );
        } else if (props.section == "liabilities") {
          dispatch(
            getLiabilityPaymentsListItems({ id: caseId, customerId: clientId })
          );
        } else {
          dispatch(
            getPolicyPaymentsListItems({ id: caseId, customerId: clientId })
          );
        }
      }
      // apiServices.Payments.getPayments(caseId, clientId)
      //   .then((response) => {
      //     console.log("get payments", response);
      //     setLoading(false);
      //     setListLoading(false);
      //     dispatch(reloadData(false));
      //     if (response.data) {
      //       if (response.data.data && response.data.data.result) {
      //         setCaseSubItemList(response.data.data?.result || []);
      //         setPaymentValue(response.data.data?.total);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     console.log(err);
      //   });
    } else if (props.subItem === "withdrawals") {
      if (caseId && clientId) {
        if (props.section == "assets") {
          dispatch(
            getAssetWithdrawalsListItems({ id: caseId, customerId: clientId })
          );
        } else {
          dispatch(
            getPolicyWithdrawalsListItems({ id: caseId, customerId: clientId })
          );
        }
      }
      // apiServices.Withdrawals.getList(caseId, clientId)
      //   .then((response) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     dispatch(reloadData(false));
      //     if (response.data) {
      //       if (response.data.data && response.data.data.result) {
      //         setCaseSubItemList(response.data.data?.result || []);
      //         setWithdrawalValue(response.data.data?.total);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     console.log(err);
      //   });
    } else if (props.subItem === "actions") {
      if (caseId && clientId) {
        if (props.section == "assets") {
          dispatch(
            getAssetActionsListItems({ id: caseId, customerId: clientId })
          );
        } else if (props.section == "liabilities") {
          dispatch(
            getLiabilityActionsListItems({ id: caseId, customerId: clientId })
          );
        } else {
          dispatch(
            getPolicyActionsListItems({ id: caseId, customerId: clientId })
          );
        }
      }
    } else if (props.subItem === "valuations") {
      if (caseId && clientId) {
        if (props.section == "assets") {
          dispatch(
            getAssetValuationsListItems({ id: caseId, customerId: clientId })
          );
        } else {
          dispatch(
            getPolicyValuationsListItems({ id: caseId, customerId: clientId })
          );
        }
      }

      // apiServices.valuation
      //   .getvaluation(caseId, clientId)
      //   .then((response) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     dispatch(reloadData(false));
      //     if (response.data) {
      //       if (response.data.data) {
      //         setCaseSubItemList(response.data.data || []);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     setListLoading(false);
      //     console.log(err);
      //   });
    }
  };
  const fetchSubItemDetail = (id: string) => {
    if (activeTab == "fund") {
      if (props.section == "assets") {
        dispatch(getAssetFundDetailById(id));
      } else {
        dispatch(getPolicyFundDetailById(id));
      }

      // apiServices.fund
      //   .getDetail(clientId, id)
      //   .then((response) => {
      //     setLoading(false);
      //     dispatch(reloadDetails(false));
      //     if (response.data) {
      //       if (response.data.data && response.data.data.length > 0) {
      //         setSubItemDetail(response.data.data[0]);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     console.log(err);
      //   });
    } else if (activeTab == "payment") {
      if (props.section == "assets") {
        dispatch(getAssetPaymentDetailById(id));
      } else if (props.section == "liabilities") {
        dispatch(getLiabilityPaymentDetailById(id));
      } else {
        dispatch(getPolicyPaymentDetailById(id));
      }
    } else if (activeTab == "withdrawal") {
      if (props.section == "assets") {
        dispatch(getAssetWithdrawalDetailById(id));
      } else {
        dispatch(getPolicyWithdrawalDetailById(id));
      }
    } else if (activeTab == "action") {
      if (props.section == "assets") {
        dispatch(getAssetActionDetailById(id));
      } else if (props.section == "liabilities") {
        dispatch(getLiabilityActionDetailById(id));
      } else {
        dispatch(getPolicyActionDetailById(id));
      }
    } else if (activeTab == "valuation") {
      if (props.section == "assets") {
        dispatch(getAssetValuationDetailById(id));
      } else {
        dispatch(getPolicyValuationDetailById(id));
      }

      // if (caseSubItemList.length > 0) {
      //   const result = caseSubItemList.find(
      //     (item: any) => item.valuation_id === id
      //   );

      //   setSubItemDetail(result ? result : {});
      // }
    }
  };
  const fetchSubSubItemDetail = (id: string) => {
    if (activeTab == "commission") {
      if (caseSubSubItemList.length > 0) {
        dispatch(getAssetPaymentsCommissionDetailById(id));
        // const result = caseSubSubItemList.find(
        //   (item: any) => item.commission_id === id
        // );
        // setSubSubItemDetail(result ? result : {});
      }
    }
  };
  const handleDetailPopup = (action: boolean) => {
    setIsOpen(action);
  };
  const handleStandardActions = (action: boolean) => {
    setLoadStandardActionPopup(action);
  };
  const getCommissionList = (paymentId: string) => {
    if (paymentId && clientId && caseId) {
      dispatch(
        getAssetPaymentsCommissionsListItems({
          paymentId,
          caseId: caseId,
          customerId: clientId,
        })
      );
    }

    // setListLoading(true);
    // apiServices.commission
    //   .getCommission(paymentId, clientId, caseId)
    //   .then((response) => {
    //     setLoading(false);
    //     setListLoading(false);
    //     if (response.data) {
    //       if (response.data.data) {
    //         setCaseSubSubItemList(response.data.data);
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     setListLoading(false);
    //     console.log(err);
    //   });
  };
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const addLoadStandardActions = () => {
    if (selectedLoadStandardActions.length > 0) {
      let case_type_value = caseType;

      let contextVal =
        caseContextList[props.section][case_type_value]["action"];

      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          contextVal
        );
        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: clientId,
            case_id: caseId,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_case_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id]["tracking_case_actions"] =
            selAct;

          const businessCategory =
            props.section === "assets"
              ? CaseCategoryMap.assets
              : props.section === "liabilities"
              ? CaseCategoryMap[configObj.renderForm?.reference_id] ?? 0
              : 1;

          actReq[configObj.renderForm?.reference_id]["business_category"] =
            businessCategory;

          setActionLoading(true);
          apiServices.tackingActions
            .addStandardActions(actReq)
            .then((response) => {
              setActionLoading(false);

              if (!response.data.isError) {
                setTimeout(() => {
                  setSelectedLoadStandardActions([]);
                  handleStandardActions(false);
                  setLoadStandardActionDate(new Date());
                  notify(response.data.message, "", "success");
                  if (clientId && caseId && props.section) {
                    if (props.section == "assets") {
                      dispatch(
                        getAssetActionsListItems({
                          id: caseId,
                          customerId: clientId,
                        })
                      );
                    } else if (props.section == "liabilities") {
                      dispatch(
                        getLiabilityActionsListItems({
                          id: caseId,
                          customerId: clientId,
                        })
                      );
                    } else {
                      dispatch(
                        getPolicyActionsListItems({
                          id: caseId,
                          customerId: clientId,
                        })
                      );
                    }
                  }
                }, 1000);
              } else if (
                response.data.isError &&
                response.data.error &&
                Object.keys(response.data.error).length
              ) {
                const errorMessage = Array.isArray(response.data.error.message)
                  ? response.data.error.message.join(", ")
                  : response.data.error.message;
                notify(errorMessage, "", "error");
              } else {
                notify("Something went wrong", "", "error");
              }

              // console.log("response---traking", response);
            })
            .catch((err) => {
              setActionLoading(false);
              notify(err.message, "", "error");
              // console.log(err);
            });
        }
      }
    }
  };

  const loadActionSelected = (action: any) => {
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }

    // setSelectedActions(act);
    setSelectedLoadStandardActions(act);
  };
  const renderDate = (date: string) => (date ? formatedDate(date) : "-");
  const renderRichText = (text: string) =>
    text ? <ReactQuillComponent value={text} /> : "-";
  const renderYesorNo = (value: number) =>
    value ? getYes_NoConversion(value) : "-";
  const CaseDetails = (activeItem: any) => {
    if (Object.keys(activeItem).length !== 0) {
      if (activeItem.case_type === "Unit-linked") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              unitLinked.length > 0 &&
              unitLinked.map((AssetInvestment: any) => (
                <div key={AssetInvestment.Unit_linked_id} className="details">
                  <p className="details_label">{AssetInvestment.label} </p>
                  <p>
                    {(() => {
                      switch (AssetInvestment.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "investment_start_date":
                          return renderDate(activeItem.investment_start_date);
                        case "investment_end_date":
                          return renderDate(activeItem.investment_end_date);
                        case "value_date":
                          return renderDate(activeItem.value_date);
                        case "status_date":
                          return renderDate(activeItem.status_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "note_text":
                          return renderRichText(activeItem.note_text);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[AssetInvestment.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Shares") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              shares.length > 0 &&
              shares.map((Assetshares: any) => (
                <div key={Assetshares.shares_id} className="details">
                  <p className="details_label">{Assetshares.label} </p>
                  <p>
                    {(() => {
                      switch (Assetshares.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "share_date_acquired":
                          return renderDate(activeItem.share_date_acquired);
                        case "value_date":
                          return renderDate(activeItem.value_date);
                        case "status_date":
                          return renderDate(activeItem.status_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "note_text":
                          return renderRichText(activeItem.note_text);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[Assetshares.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Home/Personal") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              homePersonal.length > 0 &&
              homePersonal.map((AssetHP: any) => (
                <div key={AssetHP.Home_Personal_id} className="details">
                  <p className="details_label">{AssetHP.label} </p>
                  <p>
                    {(() => {
                      switch (AssetHP.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "home_date_acquired":
                          return renderDate(activeItem.home_date_acquired);

                        case "value_date":
                          return renderDate(activeItem.value_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "note_text":
                          return renderRichText(activeItem.note_text);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[AssetHP.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Banks/B Societies") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              banksBSocieties.length > 0 &&
              banksBSocieties.map((AssetBank: any) => (
                <div key={AssetBank.Banks_B_Societies_id} className="details">
                  <p className="details_label">{AssetBank.label} </p>
                  <p>
                    {(() => {
                      switch (AssetBank.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );

                        case "balance_date":
                          return renderDate(activeItem.value_date);

                        case "bank_date_opened":
                          return renderDate(activeItem.bank_date_opened);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "note_text":
                          return renderRichText(activeItem.note_text);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[AssetBank.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Mortgages") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              Mortgages.length > 0 &&
              Mortgages.map((Mortgages: any) => (
                <div key={Mortgages.Mortgages_id} className="details">
                  <p className="details_label">{Mortgages.label} </p>
                  <p>
                    {(() => {
                      switch (Mortgages.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "mortgage_link":
                          return renderYesorNo(activeItem.mortgage_link);
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "value_date":
                          return renderDate(activeItem.value_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "amount_outstanding_date":
                          return renderDate(activeItem.amount_outstanding_date);

                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);

                        case "admin_note":
                          return renderRichText(activeItem.admin_note);

                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[Mortgages.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Loans/Lease/HP") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              LoansLeaseHP.length > 0 &&
              LoansLeaseHP.map((Loans: any) => (
                <div key={Loans.LoansLeaseHP_id} className="details">
                  <p className="details_label">{Loans.label} </p>
                  <p>
                    {(() => {
                      switch (Loans.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "amount_outstanding_date":
                          return renderDate(activeItem.amount_outstanding_date);

                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);

                        case "admin_note":
                          return renderRichText(activeItem.admin_note);

                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[Loans.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Credit Cards") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              CreditCards.length > 0 &&
              CreditCards.map((LiabilityCreditCards: any) => (
                <div
                  key={LiabilityCreditCards.CreditCards_id}
                  className="details"
                >
                  <p className="details_label">{LiabilityCreditCards.label} </p>
                  <p>
                    {(() => {
                      switch (LiabilityCreditCards.value) {
                        case "joint_indicator":
                          return (
                            getClientType(activeItem.joint_indicator) || "-"
                          );
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "amount_outstanding_date":
                          return renderDate(activeItem.amount_outstanding_date);

                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);

                        case "admin_note":
                          return renderRichText(activeItem.admin_note);

                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[LiabilityCreditCards.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Life Assurance") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              lifeAssurance.length > 0 &&
              lifeAssurance.map((policyLA: any) => (
                <div key={policyLA.lifeAssurance_id} className="details">
                  <p className="details_label">{policyLA.label} </p>
                  <p>
                    {(() => {
                      switch (policyLA.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyLA.value] || "-";
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Pensions") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              pension.length > 0 &&
              pension.map((policyP: any) => (
                <div key={policyP.pension_id} className="details">
                  <p className="details_label">{policyP.label} </p>
                  <p>
                    {(() => {
                      switch (policyP.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyP.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Investments") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              Investments.length > 0 &&
              Investments.map((policyInvestments: any) => (
                <div key={policyInvestments.Investments_id} className="details">
                  <p className="details_label">{policyInvestments.label} </p>
                  <p>
                    {(() => {
                      switch (policyInvestments.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyInvestments.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Savings Plans") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              SavingsPlan.length > 0 &&
              SavingsPlan.map((policySP: any) => (
                <div key={policySP.Savings_Plans_id} className="details">
                  <p className="details_label">{policySP.label} </p>
                  <p>
                    {(() => {
                      switch (policySP.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policySP.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Income Protection") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              IncomeProtection.length > 0 &&
              IncomeProtection.map((policyIP: any) => (
                <div key={policyIP.Income_Protection_id} className="details">
                  <p className="details_label">{policyIP.label} </p>
                  <p>
                    {(() => {
                      switch (policyIP.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyIP.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "Health Assurance") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              HealthAssurance.length > 0 &&
              HealthAssurance.map((policyHA: any) => (
                <div key={policyHA.Income_Protection_id} className="details">
                  <p className="details_label">{policyHA.label} </p>
                  <p>
                    {(() => {
                      switch (policyHA.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                          return renderDate(activeItem.end_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyHA.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      } else if (activeItem.case_type === "General") {
        return (
          <div>
            {Object.keys(activeItem).length !== 0 &&
              General.length > 0 &&
              General.map((policyGeneral: any) => (
                <div key={policyGeneral.General_id} className="details">
                  <p className="details_label">{policyGeneral.label} </p>
                  <p>
                    {(() => {
                      switch (policyGeneral.value) {
                        case "policy_holder":
                          return getClientType(activeItem.policy_holder) || "-";
                        case "start_date":
                          return renderDate(activeItem.start_date);
                        case "end_date":
                        case "value_date":
                          return renderDate(activeItem.value_date);
                        case "valuation_date":
                          return renderDate(activeItem.valuation_date);
                        case "provider_note":
                          return renderRichText(activeItem.provider_note);
                        case "benefit_text":
                          return renderRichText(activeItem.benefit_text);
                        case "in_trust_note":
                          return renderRichText(activeItem.in_trust_note);
                        case "admin_note":
                          return renderRichText(activeItem.admin_note);
                        case "report_note":
                          return renderRichText(activeItem.report_note);
                        default:
                          return activeItem[policyGeneral.value];
                      }
                    })()}
                  </p>
                </div>
              ))}
          </div>
        );
      }
    } else {
      return <p>No details available</p>;
    }
  };

  return (
    <div
      style={{
        height: "calc(100vh - 50px)",
        overflow: "auto",
        marginBottom: "12px",
        width: "100%",
      }}
    >
      <CaseNavList
        {...{
          subItem: props.subItem,
          section: props.section,
          tab: props.tab,
          menuItem: props.subItem,
          caseType,
          caseDetail: subItemDetail,
          handleDetailPopup,
          handleStandardActions,
        }}
      />
      {props.section && action && (
        <CaseForm
          mode={action ? action : ""}
          section={props.section ? props.section : ""}
          caseType={caseType}
          caseDetail={
            props.tab == "commission" ? subSubItemDetail : subItemDetail
          }
          caseItemList={
            props.tab == "commission" ? caseSubSubItemList : caseSubItemList
          }
          tab={activeTab}
          subItem={props.subItem}
          fundValue={fundValue}
          paymentValue={paymentValue}
          withdrawalValue={withdrawalValue}
        />
      )}

      {listLoading ? (
        <ContentLoader />
      ) : (
        <>
          {action && !["add", "edit"].includes(action) && (
            <>
              <div
                style={{
                  height: "100%",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                {props.tab === "distribution" &&
                commissionDistributionList.length > 0 ? (
                  <>
                    <CaseList
                      module={props.tab}
                      data={commissionDistributionList}
                      activeItem={distributionDetail}
                      section={props.section || ""}
                      activeTab={activeTab}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    {props.tab === "commission" &&
                    caseSubSubItemList.length > 0 ? (
                      <CaseList
                        module={props.tab}
                        data={caseSubSubItemList}
                        activeItem={subSubItemDetail}
                        section={props.section || ""}
                        activeTab={activeTab}
                      />
                    ) : (
                      caseSubItemList.length > 0 && (
                        <CaseList
                          module={props.subItem}
                          data={caseSubItemList}
                          activeItem={subItemDetail}
                          section={props.section || ""}
                          activeTab={activeTab}
                        />
                      )
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {!action && (
            <>
              {props.tab === "distribution" &&
              commissionDistributionList.length == 0 ? (
                <div className={styles.emptyState}>
                  <Label size="large" style={{ color: "red" }}>
                    There are no active records - click Add New to create a new
                    record
                  </Label>
                </div>
              ) : (
                <>
                  {" "}
                  {props.tab === "commission" &&
                  caseSubSubItemList.length == 0 ? (
                    <div className={styles.emptyState}>
                      <Label size="large" style={{ color: "red" }}>
                        There are no active records - click Add New to create a
                        new record
                      </Label>
                    </div>
                  ) : (
                    caseSubItemList.length == 0 && (
                      <div className={styles.emptyState}>
                        <Label size="large" style={{ color: "red" }}>
                          There are no active records - click Add New to create
                          a new record
                        </Label>
                      </div>
                    )
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <OverlayDrawer
        position={"end"}
        open={isOpen}
        onOpenChange={(_, { open }) => handleDetailPopup(open)}
        className={"common-drawer"}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => handleDetailPopup(false)}
              />
            }
          >
            Case Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{CaseDetails(caseDetail)}</DrawerBody>
      </OverlayDrawer>
      <Dialog open={loadStandardActionPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <LoadStandardAction
                loadStandardActionDate={loadStandardActionDate}
                setLoadStandardActionDate={setLoadStandardActionDate}
                selectedLoadStandardActions={selectedLoadStandardActions}
                loadActionSelected={loadActionSelected}
                type="case"
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedLoadStandardActions([]);
                    setLoadStandardActionPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={addLoadStandardActions}
                icon={actionLoading ? <Spinner size="tiny" /> : null}
              >
                {actionLoading ? "Processing..." : "Submit"}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
    </div>
  );
};

export default CaseSubItemDetailPage;

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@fluentui/react-components";
import { Hamburger } from "@fluentui/react-nav-preview";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ModalDialog from "../components/ModalDialog";
import Breadcrumbs from "./breadcrumb/Breadcrumbs";
import Navbar from "./navbar/Navbar";

import SystemManagerSidebar from "./sidebar/SystemManagerSidebar";
import Sidebar from "./sidebar/Sidebar";
import Timer from "../pages/client/TimerFunction/TimerFunction";
import CommissionSidebar from "../pages/Commission/CommissionSidebar/Sidebar";

import { AppDispatch, RootState } from "../redux/store";
import { doLogout } from "../redux/authSlice";
import {
  checkUserPermission,
  convertStringToArray,
  isObjectEmpty,
} from "../utils";
import { UserPermissionsList } from "../config";
import ASCButton from "../components/Button";
import { DocumentBulletList20Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import {
  openActionListDetailPopup,
  setActionListSelectedItem,
} from "../redux/appSettingsSlice";

import "./style.css";

type ComponentProps = Record<string, any>;

function ASCLayout(props: ComponentProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  // Idle timer settings
  const timeout = 1800000; // 30 minutes
  const promptBeforeIdle = 10000; // 10 seconds

  // States
  const [remaining, setRemaining] = useState<number>(timeout / 1000);
  const [state, setState] = useState<"Active" | "Idle" | "Prompted">("Active");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);

  const isUtilities = location.pathname.startsWith("/utilities");
  const isAdminRoute = location.pathname.startsWith("/system-manager");
  const isCommission = location.pathname.startsWith("/commission");

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const appSettings = useSelector((state: RootState) => state.appSettings);

  // Idle timer handlers
  const onIdle = () => {
    setState("Idle");
    setModalOpen(false);
    dispatch(doLogout());
  };

  const onActive = () => {
    setState("Active");
    setModalOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setModalOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);
  useEffect(() => {
    if (!["action-list", "/action-list"].includes(location.pathname)) {
      dispatch(setActionListSelectedItem({}));
    }
  }, [location.pathname, dispatch]);
  const handleStillHere = () => {
    activate();
  };

  const renderHamburgerWithTooltip = () => (
    <Tooltip content="Navigation" relationship="label">
      <Hamburger onClick={() => setSidebarOpen(!isSidebarOpen)} />
    </Tooltip>
  );

  const renderSidebar = () => {
    if (isAdminRoute) return <SystemManagerSidebar />;
    if (isCommission) return <CommissionSidebar />;
    if (isUtilities) return <Sidebar />;
    return null;
  };
  const excludedRoutes = [
    "clients",
    "system-manager",
    "utilities",
    "commission",
  ];
  const isExcludedRoute =
    location?.pathname &&
    excludedRoutes.some((route) => location.pathname.includes(route));
  const isFloatingMenuOpen = useSelector(
    (state: any) => state.opentimer.storedValue
  );
  return (
    <>
      {/* Session Timeout Modal */}
      <ModalDialog
        options={{
          open: isModalOpen,
          title: "Your session is about to expire",
          content_line_2:
            "You're being timed out due to inactivity. Please choose to stay logged in or log off.",
          content_line_3: "Otherwise, you will be logged off automatically.",
          yes: {
            label: `Stay Logged In (${remaining}s)`,
            type: "primary",
            onclick: handleStillHere,
          },
          no: {
            label: "Log Off",
            onclick: () => dispatch(doLogout()),
          },
        }}
      />

      {/* Page Layout */}
      <div className="body-wrapper">
        <div className="page-wrapper">
          {/* Header */}
          <div className="top-header-bar">
            <Header />
          </div>

          {/* Sidebar and Content */}
          <div
            className={isAdminRoute || isUtilities || isCommission ? "" : ""}
          >
            {/* Main Content */}
            <div className={isSidebarOpen ? "content" : ""}>
              {!isSidebarOpen && renderHamburgerWithTooltip()}
              <div
                className={
                  isAdminRoute || isUtilities || isCommission
                    ? ""
                    : "content-area"
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Navbar />
                  {isFloatingMenuOpen === true ? <Timer /> : ""}
                </div>
                <div className="content-area">
                  {/* Breadcrumbs and Add Client Button */}
                  <div className="breadcrumbs-and-button">
                    {isExcludedRoute ? <div></div> : <Breadcrumbs />}

                    {["/", "clients", "/clients"].includes(location.pathname) &&
                      checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_ADD_CLIENT
                      ) && (
                        <ASCButton
                          shape="rounded"
                          label="Add Client"
                          className="asc-button-primary"
                          appearance="primary"
                          onItemClick={() => navigate(`/clients/add`)}
                          size="medium"
                        />
                      )}
                    {["action-list", "/action-list"].includes(
                      location.pathname
                    ) && (
                      <>
                        {!convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("actionlist_detail_btn") && (
                          <Button
                            onClick={() => {
                              dispatch(openActionListDetailPopup(true));
                            }}
                            icon={<DocumentBulletList20Regular />}
                            style={{ width: "fit-content" }}
                            disabled={
                              !["action-list", "/action-list"].includes(
                                location.pathname
                              ) ||
                              isObjectEmpty(appSettings.actionListSelectedItem)
                            }
                          >
                            Details
                          </Button>
                        )}
                      </>
                    )}
                  </div>

                  <Outlet />
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ASCLayout;
